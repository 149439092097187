import { MeasurementOptions } from "./types";

export const baseMeasurementOptions: MeasurementOptions = {
  intensity: {
    id: "intensity",
    name: "Intensity",
    children: [
      "intensity-total",
      "intensity-mean",
      "intensity-std",
      "intensity-MAD",
      "intensity-min",
      "intensity-max",
      "intensity-lower-quartile",
      "intensity-upper-quartile",
    ],
    state: "off",
    thingType: "all",
  },
  "intensity-total": {
    id: "intensity-total",
    name: "Total",
    parent: "intensity",
    state: "off",
    hasChannels: true,
    thingType: "all",
  },
  "intensity-mean": {
    id: "intensity-mean",
    name: "Mean",
    parent: "intensity",
    state: "off",
    hasChannels: true,
    thingType: "all",
  },
  "intensity-std": {
    id: "intensity-std",
    name: "Standard Deviation",
    parent: "intensity",
    state: "off",
    hasChannels: true,
    thingType: "all",
  },
  "intensity-MAD": {
    id: "intensity-MAD",
    name: "MAD",
    parent: "intensity",
    state: "off",
    hasChannels: true,
    thingType: "all",
  },
  "intensity-min": {
    id: "intensity-min",
    name: "Minimum",
    parent: "intensity",
    state: "off",
    hasChannels: true,
    thingType: "all",
  },
  "intensity-max": {
    id: "intensity-max",
    name: "Maximum",
    parent: "intensity",
    state: "off",
    hasChannels: true,
    thingType: "all",
  },
  "intensity-lower-quartile": {
    id: "intensity-lower-quartile",
    name: "Lower Quartile",
    parent: "intensity",
    state: "off",
    hasChannels: true,
    thingType: "all",
  },
  "intensity-upper-quartile": {
    id: "intensity-upper-quartile",
    name: "Upper Quartile",
    parent: "intensity",
    state: "off",
    hasChannels: true,
    thingType: "all",
  },
  "object-geometry": {
    id: "object-geometry",
    name: "Object Geometry",
    children: [
      "object-geometry-area",
      "object-geometry-perimeter",
      "object-geometry-extent",
      "object-geometry-bbox-area",
      "object-geometry-eqpc",
      "object-geometry-ped",
      "object-geometry-sphericity",
      "object-geometry-compactness",
    ],
    state: "off",
    thingType: "Object",
  },
  "object-geometry-area": {
    id: "object-geometry-area",
    name: "Area",
    state: "off",
    parent: "object-geometry",
    thingType: "Object",
  },
  "object-geometry-perimeter": {
    id: "object-geometry-perimeter",
    name: "Perimeter",
    state: "off",
    parent: "object-geometry",
    thingType: "Object",
  },
  "object-geometry-extent": {
    id: "object-geometry-extent",
    name: "Extent",
    state: "off",
    parent: "object-geometry",
    thingType: "Object",
  },
  "object-geometry-bbox-area": {
    id: "object-geometry-bbox-area",
    name: "BBox Area",
    state: "off",
    parent: "object-geometry",
    thingType: "Object",
  },
  "object-geometry-eqpc": {
    id: "object-geometry-eqpc",
    name: "Equivalent Diameter",
    state: "off",
    parent: "object-geometry",
    thingType: "Object",
  },
  "object-geometry-ped": {
    id: "object-geometry-ped",
    name: "PED",
    state: "off",
    parent: "object-geometry",
    thingType: "Object",
  },
  "object-geometry-sphericity": {
    id: "object-geometry-sphericity",
    name: "Sphericity",
    state: "off",
    parent: "object-geometry",
    thingType: "Object",
  },
  "object-geometry-compactness": {
    id: "object-geometry-compactness",
    name: "Compactness",
    state: "off",
    parent: "object-geometry",
    thingType: "Object",
  },
};
